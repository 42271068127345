/*
  httpReq() is a function that can load data over HTTP and return them.
  We do not using axios coz of reducing o flibraries volume. */

'use strict';

function httpReq(href) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', href, false)
    xhr.send()
    if (xhr.status == 200) {
      resolve(xhr.responseText)
    } else {
      reject({ code: xhr.status, message: xhr.statusText })
    }
  })
}

export default httpReq
