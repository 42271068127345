import mapboxgl from 'mapbox-gl'
import config from './config'
// import peopleSource from './data/index'
import httpReq from './libs/HttpReq.js'
import './style.css'

mapboxgl.accessToken = config.mapbox.accessToken

const map = new mapboxgl.Map({
    container: 'map',
    style: config.mapbox.style,
    dragRotate: false,
    center: config.mapbox.center,
    zoom: config.mapbox.zoom,
    showZoom: true,
    showCompass: true
})
  .on('load', function () {
    const layers = map.getStyle().layers
    let i

    // Setup language
    for (i = 0; i < layers.length; i++) {
      try {
        map.setLayoutProperty(layers[i].id, 'text-field', ['get', 'name_ru'])
      } catch (err) {}
    }

    httpReq('/data.json')
      .then(resp => {
        console.log('Data loaded.')
        try {
          let source = JSON.parse(resp)
          console.log('Got points:', source.data.features.length)
          // Create data source
          map.addSource('people', source)
          // Create vusial layer
          map.addLayer({
            'id': 'people',
            'type': 'circle',
            'source': 'people',
            'paint': {
              'circle-radius': { 'stops': [[8, 3], [11, 6], [14, 9], [17, 12], [20, 15]] },
              'circle-color': '#990000',
              'circle-opacity': 0.6
            }
          })
        } catch (err) {
          console.log('Loaded data is not JSON we can use.', err)
        }
      })
      .catch((err) => {
        console.log('HTTP error:', err)
      })
  })
  .on('click', 'people', function (e) {
    const renderHtml = function (props) {
      return new Promise((resolve) => {
        let html = ''
        html += '<h1><a href="' + config.openlist.serverURL + props.href + '">' + props.name + '</a></h1>'
        html += '<address>' + props.address + '</address>'
        if (props.photo && props.photo != null) {
          let img = new Image()
          img.src = config.openlist.serverURL + props.photo
          img.onload = () => {
            html += '<img src="' + img.src + '">'
            resolve(html)
          }
        } else {
          resolve(html)
        }
      })
    }

    let coords = e.features[0].geometry.coordinates.slice()

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coords[0]) > 180) {
        coords[0] += e.lngLat.lng > coords[0] ? 360 : -360
    }

    // Generate HTML for popup
    renderHtml(e.features[0].properties)
      .then((html) => {
        new mapboxgl.Popup()
          .setLngLat(coords)
          .setHTML(html)
          .addTo(map);
      })

  })
  .on('mouseenter', 'people', function () {
    map.getCanvas().style.cursor = 'pointer'
  })
  map.on('mouseleave', 'people', function () {
    map.getCanvas().style.cursor = ''
  })