const mapbox = {
  accessToken: 'pk.eyJ1Ijoib3Blbmxpc3QiLCJhIjoiY2pqeTQ4eGwxNXI1azNwcm1iZm9uaG5kaiJ9.KOxopTiI4NqIAMLGEmpEWQ',
  style: 'mapbox://styles/mapbox/dark-v9',
  center: {'lng': 37.61764775504926, 'lat': 55.753812934171464},
  zoom: 12
}

const openlist = {
  serverURL: 'https://ru.openlist.wiki/'
}

export default {
  mapbox,
  openlist
}
